import { Link } from "gatsby";
import { motion } from "framer-motion";
import { variantPages } from "../animations/pageTransition";
import { StaticImage } from "gatsby-plugin-image";
import MobileIndexForm from "../components/callback/mobileIndexForm";
import React from "react";
import MetaTag from "../components/metaTag";
import { Iconsone, Iconsthree, Iconstwo } from "../components/svg/icons";
import {
  Layerfive,
  Layerfour,
  Layerone,
  Layerthree,
  Layertwo,
} from "../components/svg/background";
import MapRoute from "../components/mapRoute";
import cx from "classnames";
import { indexSEO } from "../data/seo";
import * as styles from "../styles/pages/index.module.scss";
import {
  Aboutfive,
  Aboutfour,
  Aboutone,
  Aboutsix,
  Aboutthree,
  Abouttwo,
} from "../components/svg/about";
import { useWindowSize } from "react-use";
import Logo from "../components/svg/logo";
import Feedback from "../components/service/feedback";
import Footer from "../components/footer";
import { TbRoute } from "react-icons/tb";

function IndexPage() {
  const { width } = useWindowSize();

  return (
    <motion.main
      variants={variantPages}
      initial="initial"
      exit="exit"
      animate="animate"
    >
      <MetaTag data={indexSEO} />
      <section className={cx(styles.container, styles.main)}>
        <StaticImage
          src="../images/background.png"
          className={styles.background}
          placeholder="none"
          alt="карта пнг"
        />
        <div className={styles.logomobile}>
          <Logo />
        </div>
        <h1 className={styles.title}>
          Перевезем автомобиль
          <br /> бережно и быстро <br />в любой город России
        </h1>
        <div className={styles.advantages}>
          <p>Выгодные цены</p>
          <p>Груз застрахован на 30 миллионов рублей </p>
          <p>Свой транспорт</p>
        </div>
        {width < 1024 && <MobileIndexForm />}
        <div className={styles.links}>
          <Link to="/indoortowtruck" className={styles.btn}>
            <Iconsone className={styles.icon} />
            <p className={styles.label}>Крытый эвакуатор</p>

            <Layerfour className={styles.layer} />
          </Link>

          <Link to="/carcarrier" className={styles.btn}>
            <Iconstwo className={styles.icon} />

            <p className={styles.label}>Автовоз</p>
            <Layerone className={styles.layer} />
          </Link>

          <Link to="/minicarcarrier" className={styles.btn}>
            <Iconsthree className={styles.icon} />
            <p className={styles.label}>Мини автовоз</p>
            <Layerfive className={styles.layer} />
          </Link>

          <Link to="/transportationofthecar" className={styles.btn}>
            <Iconsone className={styles.icon} />
            <p className={styles.label}>Траснспортировка авто</p>
            <Layerthree className={styles.layer} />
          </Link>

          <Link to="/directions" className={styles.btn}>
            <TbRoute className={styles.icon} style={{ opacity: 0.6 }} />
            <p className={styles.label}>Актуальные направления</p>
            <Layertwo className={styles.layer} />
          </Link>
        </div>
      </section>

      <section className={cx(styles.container, styles.schemework)}>
        <h2>Схема работы</h2>
        <div className={styles.info}>
          <div className={styles.block}>
            <p className={styles.number}>01</p>
            <p>
              Вы оставляете заявку на сайте или звоните нам на{" "}
              <a href="tel:+78005551163" className={styles.phone}>
                +7 (800) 555-11-63
              </a>{" "}
              (звонок бесплатный)
            </p>
          </div>
          <div className={styles.block}>
            <p className={styles.number}>02</p>
            <p>
              Менеджер нашей компании уточняет все детали и делает расчет
              стоимости перевозки
            </p>
          </div>
          <div className={styles.block}>
            <p className={styles.number}>03</p>
            <p>
              Мы определяемся с автовозом по необходимому маршруту и заключаем с
              Вами договор. Принимаем и загружаем автомобиль.
            </p>
          </div>
        </div>
      </section>

      <section className={cx(styles.about, styles.container)}>
        <h2>О нас</h2>
        <div className={styles.info}>
          <div className={cx(styles.horizontal, styles.block)}>
            <div className={styles.left}>
              <Aboutfour className={styles.icon} />

              <h3>Индивидуальный расчет</h3>
            </div>
            <div className={styles.right}>
              <p>
                <span>Что влияет на цену:</span>
                <br />
                Габариты автомобиля Состояние Стоимость Направление Расстояние
                перевозки
              </p>
            </div>
          </div>

          <div className={cx(styles.vertical, styles.block)}>
            <div className={styles.left}>
              <Abouttwo className={styles.icon} />
              <h3>Профессионализм</h3>
            </div>
            <div className={styles.right}>
              <p>
                <span>
                  Сеть охраняемых терминалов-стоянок по всей стране имеет:
                </span>
                <br />
                <br />
                - Удобные подъездные пути
                <br />
                - Систему видеонаблюдения с покрытием 100% территории
                <br />
                - Систему охранной сигнализации
                <br />
                - Систему вентиляции <br />
                - Ливневые канализации
                <br />- Противопожарное оснащение
              </p>
            </div>
          </div>

          <div className={cx(styles.horizontal, styles.block)}>
            <div className={styles.left}>
              <Aboutthree className={styles.icon} />
              <h3>Юридическая помощь</h3>
            </div>
            <div className={styles.right}>
              <p>
                <span>Работаем с юридическими и физическими лицами</span>
                <br />
                <br />
                Составляем договор и акт приема-передачи
              </p>
            </div>
          </div>

          <div className={cx(styles.vertical, styles.block)}>
            <div className={styles.left}>
              <Aboutsix className={styles.icon} />
              <h3>Надежность</h3>
            </div>
            <div className={styles.right}>
              <p>
                На каждой стоянке имеются зоны погрузки-выгрузки и инспекции
                автомобилей, места хранения автомобилей и зоны ожидания для
                автовозов. <br />
                <br />
                Территория находится под высокотехнологичной системой
                видеонаблюдения и контролируется службой охраны. Диспетчерская
                служба с вами на связи 24/7 и позволяет следить за качеством
                выполняемых услуг.
                <br />
                <br />В каждом терминале поддерживается оптимальный
                температурно-влажностный режим, установлена мощная система
                вентиляции и созданы все необходимые условия для обеспечения
                сохранности авто на протяжении всего срока хранения.
              </p>
            </div>
          </div>

          <div className={cx(styles.horizontal, styles.block)}>
            <div className={styles.left}>
              <Aboutone className={styles.icon} />
              <h3>Опыт и ответственность</h3>
            </div>
            <div className={styles.right}>
              <p>
                Компания «Автовоз Логистик» имеет богатый опыт в хранении
                отечественных и зарубежных автомобилей и спецтехники. Передайте
                свой транспорт любого размера и типа для безопасного хранения в
                современном терминале. Мы гарантируем надёжность до и после
                перевозки автомобиля.
              </p>
            </div>
          </div>

          <div className={cx(styles.horizontal, styles.block)}>
            <div className={styles.left}>
              <Aboutfive className={styles.icon} />
              <h3>
                Когда
                <br />
                обращаться
              </h3>
            </div>
            <div className={styles.right}>
              <p>
                - Необходимо быстро сдать автомобиль и продолжить заниматься
                своими делами не дожидаясь приезда и загрузки автовоза
                <br />
                - Не можете забрать свой автомобиль с автовоза в день его
                приезда
                <br />- Нужно срочно перевезти авто, но времени заниматься
                оформлением всех документов нет
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={cx(styles.container, styles.map)}>
        <MapRoute />
      </section>

      <section className={cx(styles.container, styles.guarantees)}>
        <h2>
          Автовоз Логистик - для тех, кому важны условия перевозки своего
          автомобиля!
        </h2>
        <div className={styles.info}>
          <p className={styles.item}>
            <span>1. Страхование ответственности перевозчика.</span> <br />
            Страхование автомобиля необходимый этап каждой перевозки. Все
            автомобили, перевозимые на наших автовозах, застрахованы на сумму 30
            млн руб. Этот вид страхования является обязательным и клиент за него
            не доплачивает. Ответственность перевозчиков подразумевает следующие
            риски:
            <br />
            - Повреждение, утрата, хищение груза
            <br />
            - Причинение вреда имуществу третьих лиц
            <br />- Причинение вреда жизни и здоровью третьих лиц
            <br />
            Договором страхования ответственности не могут покрываться убытки
            от:
            <br />
            - Стихийных бедствий
            <br />
            - ДТП по вине третьего лица, либо по вине дорожных служб
            <br />
            - Действий непреодолимой силы <br />
            - Провала мостов, тоннелей и т.д.
            <br />
            - Пожаров, взрывов <br />
            - Опрокидывание автовоза
            <br />- Хищения груза на охраняемой стоянке, либо следующего с
            охранным сопровождением
          </p>
          <p className={styles.item}>
            <span>2. Страховой полис.</span>
            <br />
            Для того чтобы обезопасить себя и свой автомобиль от всех рисков
            отдельно оформляется расширенный страховой полис. Он покрывает
            полную стоимость автомобиля в случае его уничтожения. Оформление
            занимает один день. Страхователем выступает компания «Автовоз
            Логистик», а выгодоприобретателем будете вы. Стоимость такого вида
            страхования от 2000 рублей, в зависимости от рыночной стоимости
            автомобиля на момент перевозки. Страховка действует на протяжении
            всей перевозки.
          </p>
        </div>
      </section>

      <section className={cx(styles.container, styles.documents)}>
        <h2>Документы</h2>
        <p>скачать PDF:</p>
        <div className={styles.download}>
          <a href="/doc/dogovor.pdf" target="_blank" className={styles.link}>
            Образец договора
          </a>
          <a href="/doc/akt_priema.pdf" target="_blank" className={styles.link}>
            Акт приема и передачи
          </a>
        </div>
      </section>

      <section className={cx(styles.container, styles.choise)}>
        <h2>Выбор перевозчика</h2>
        <div className={styles.info}>
          <p className={styles.item}>
            <span>Как сделать правильный выбор?</span>
          </p>
          <p className={styles.item}>
            1. Имидж и отзывы о компании. Крупные компании-перевозчики, как
            правило, предъявляют высокие требования к техническому состоянию
            парков и подготовке кадров.
          </p>
          <p className={styles.item}>
            2. Правила перевозки. Крупные компаний придерживаются строгого
            регламента, который включает: осмотр, приём и сдачу, погрузку,
            крепление автомобилей, отчеты о текущем местоположении,
            документооборот, бухгалтерскую отчетность, порядок оплаты и другие
            важные аспекты. Все эти требования направлены на соблюдение качества
            и сроков перевозки. Компания «Автовоз Логистик» придерживается
            соответствия европейским стандартам качества работы перевозчиков.
            Имея опыт работы с высокими требованиями, на протяжении последних
            лет, мы успешно перевозим более 1000 автомобилей в месяц.
          </p>
          <p className={styles.item}>
            3. Масштаб автопарка. Парк компании «Автовоз Логистик» насчитывает
            десятки автовозов различного типа, благодаря которым организованы
            регулярные перевозки, связывающие все федеральные округа России:
            Центральный, Северо-Западный, Южный, Приволжский, Уральский и
            Сибирский. Сеть региональных филиалов позволяет достаточно быстро
            собирать автомобили до полных лотов. А главное – мы несём
            ответственность на всех этапах перевозки.
          </p>
        </div>
      </section>

      <section className={cx(styles.container, styles.cooperation)}>
        <h2>Сотрудничество</h2>
        <div className={styles.info}>
          <p className={styles.item}>
            <span>Нашим партнёром могут стать:</span>
          </p>
          <p className={styles.item}>
            <span>Перекупы</span>
            <br />
            Приглашаем к сотрудничеству частных продавцов автомобилей. <br />
            Мы предлагаем:
            <br />
            - Расширить географию покупателей <br />
            - Точные сроки и стоимость доставки <br />
            - Персонального агента
            <br />
            - Оперативное согласование перевозки
            <br />- Специальные условия
          </p>
          <p className={styles.item}>
            <span>Автосалоны</span>
            <br />
            Устали искать подходящего перевозчика? У нас есть предложение:
            <br />
            - При регулярной перевозке нескольких автомобилей в месяц, вы
            получите возможность в «режиме онлайн» отправлять заявки на
            перевозку, самостоятельно планируя отгрузку и передачу авто
            конечному покупателю
            <br />
            - Вы сможете в любой момент распечатать бухгалтерские документы, а
            при необходимости мы предоставим пакет по каждому перевозимому авто.
            <br />- Особые финансовые условия за счет отсутствия посредников,
            позволяют значительно снизить стоимость доставки
          </p>
          <p className={styles.item}>
            <span>Агенты</span>
            <br />
            Наша региональная сеть стремительно растёт, а штат расширяется. Мы
            рады видеть в числе партнёров компании ответственных сотрудников.
            Если вы имеете представление о процессе логистики, легко общаетесь с
            людьми, умеете организовать свою работу – свяжитесь с нами и мы
            обсудим сотрудничество.
          </p>
        </div>
        <button
          className={styles.callback}
          onClick={() => window.scrollTo(0, 0)}
        >
          Оставить заявку
        </button>
      </section>

      <section className={styles.container}>
        <Feedback />
      </section>
      <Footer />
    </motion.main>
  );
}

export default IndexPage;
