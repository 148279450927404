export const indexSEO = {
  title: "Главная страница | Avtovoz Logistic",
  description:
    "Компания Автовоз Логистик занимается перевозкой автомобилей по России. У нас все автомобили проходят страхование от различных случаев. Поэтому вам не о чем беспокоится заказывая перевозку автомобиля автовозом у нас.",
  keywords:
    "автовоз, автовоз логистик, перевезти авто на автовозе, перевозка автомобиля, перегон авто, доставка авто, перевозка легковых автомобилей",
  themeColor: "#000c33",
  slug: "/",
  preview: "/preview.jpg",
};

export const indoortowtruckSEO = {
  title: "Крытый эвакуатор | Avtovoz Logistic",
  description: "Перевозка автомобиля на крытом эвакуаторе по всей России",
  keywords:
    "перевозка на крытом эвакуаторе, автовоз, автовоз логистик, перевезти авто на автовозе, перевозка автомобиля, перегон авто, доставка авто, перевозка легковых автомобилей",
  themeColor: "#000c33",
  slug: "/indoortowtruck",
  preview: "/preview.jpg",
};

export const carcarrierSEO = {
  title: "Автовоз | Avtovoz Logistic",
  description: "Перевозка автомобиля автовозом по всей России",
  keywords:
    "автовоз, автовоз логистик, перевезти авто на автовозе, перевозка автомобиля, перегон авто, доставка авто, перевозка легковых автомобилей",
  themeColor: "#000c33",
  slug: "/carcarrier",
  preview: "/preview.jpg",
};

export const directionsSEO = {
  title: "Актуальные направления | Avtovoz Logistic",
  description: "Актуальные направления перевозки автомобиля по России",
  keywords:
    "индивидуальная перевозка автомобиля, автовоз, автовоз логистик, перевезти авто на автовозе, перевозка автомобиля, перегон авто, доставка авто, перевозка легковых автомобилей",
  themeColor: "#000c33",
  slug: "/directions",
  preview: "/preview.jpg",
};

export const minicarcarrierSEO = {
  title: "Мини автовоз | Avtovoz Logistic",
  description: "Перевозка автомобиля мини автовозом по всей России",
  keywords:
    "перевозка авто на мини автовозе, автовоз, автовоз логистик, перевезти авто на автовозе, перевозка автомобиля, перегон авто, доставка авто, перевозка легковых автомобилей",
  themeColor: "#000c33",
  slug: "/indoortowtruck",
  preview: "/preview.jpg",
};

export const transportationofthecarSEO = {
  title: "Транспортировка авто | Avtovoz Logistic",
  description: "Транспортировка автомобиля по всей России",
  keywords:
    "транспортировка автомобиля, автовоз, автовоз логистик, перевезти авто на автовозе, перевозка автомобиля, перегон авто, доставка авто, перевозка легковых автомобилей",
  themeColor: "#000c33",
  slug: "/indoortowtruck",
  preview: "/preview.jpg",
};
