import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import cx from "classnames";
import * as styles from "./form.module.scss";
import { useWindowSize } from "react-use";
import AirDatepicker from "air-datepicker";
import localeRu from "air-datepicker/locale/ru";
import axios from "axios";
import { StaticImage } from "gatsby-plugin-image";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "gatsby";
import { variantInput } from "../../animations/form";
import Complete from "./complete";

const hints = [
  "Выберите тип автомобиля",
  "Введите марку и модель автомобиля",
  "Введите откуда и куда доставить автомобиль",
  "Выберите дату отправления",
  "Введите контакты для обратной связи",
];

function MobileIndexForm() {
  const datePicker = useRef();
  const airDate = useRef();
  const [timeZone, setTimeZone] = useState("");
  const [count, setCount] = useState(1);
  const [backBtn, setbBackBtn] = useState(false);
  const [nextBtn, setNextBtn] = useState(false);
  const [formComplete, setFormComplete] = useState(false);

  const [typeAuto, setTypeAuto] = useState("");
  const [markAuto, setMarkAuto] = useState("");
  const [startingPoint, setStartingPoint] = useState("");
  const [finalPoint, setFinalPoint] = useState("");
  const [modelAuto, setModelAuto] = useState("");
  const [dateSend, setDateSend] = useState("");
  const [dateFormatted, setDateFormatted] = useState("");
  const [nameUser, setNameUser] = useState("");
  const [contactUser, setContactUser] = useState("");
  const [time, setTime] = useState("");
  const [promoCode, setPromoCode] = useState("");

  const [nextAnimation, setNextAnimation] = useState();

  const { width } = useWindowSize();

  const url = "/api/telegram.php";

  const { register, handleSubmit } = useForm({ mode: "onBlur" });

  const selectTypeauto = (value) => {
    return setTypeAuto(value);
  };

  const onSubmit = () =>
    axios
      .post(url, {
        telegram_message:
          `Заявка с сайта:` +
          "%0A" +
          `Тип авто: ${typeAuto}` +
          "%0A" +
          `Марка и модель: ${markAuto} ${modelAuto}` +
          "%0A" +
          `Откуда - куда: ${startingPoint} - ${finalPoint}` +
          "%0A" +
          `Дата отправления: ${dateFormatted}` +
          "%0A" +
          `Контакты:` +
          "%0A" +
          `${nameUser}` +
          "%0A" +
          `${contactUser}` +
          "%0A" +
          `Время для связи: ${time} ${timeZone}` +
          "%0A" +
          `Промокод: ${promoCode}`,
      })
      .then(function () {
        setCount(1);
        setbBackBtn(false);
        setNextBtn(false);
        setFormComplete(false);
        setTypeAuto("");
        setMarkAuto("");
        setModelAuto("");
        setStartingPoint("");
        setFinalPoint("");
        setDateSend("");
        setDateFormatted("");
        setContactUser("");
        setTime("");
        setFormComplete(!formComplete);
      });

  useEffect(() => {
    if (count === 1 && typeAuto) {
      setNextBtn(true);
    }

    if (count === 2 && markAuto && modelAuto) {
      setNextBtn(true);
    } else if (count === 2 && (markAuto === "" || modelAuto === "")) {
      setNextBtn(false);
    }

    if (count === 3 && startingPoint && finalPoint) {
      setNextBtn(true);
    } else if (count === 3 && (startingPoint === "" || finalPoint === "")) {
      setNextBtn(false);
    }

    if (count === 4 && dateSend) {
      setNextBtn(true);
    } else if (count === 4 && !dateSend) {
      setNextBtn(false);
    }

    if (count === 5 && nameUser && contactUser && time) {
      setNextBtn(true);
    } else if (
      count === 5 &&
      (nameUser === "" || contactUser === "" || time === "")
    ) {
      setNextBtn(false);
    }

    if (count > 1) {
      setbBackBtn(true);
    } else {
      setbBackBtn(false);
    }
  }, [
    count,
    typeAuto,
    markAuto,
    modelAuto,
    startingPoint,
    finalPoint,
    dateFormatted,
    nameUser,
    contactUser,
    time,
    dateSend,
  ]);

  useEffect(() => {
    if (count === 4) {
      let nowDate = new Date();
      setTimeout(() => {
        airDate.current = new AirDatepicker(datePicker.current, {
          locale: localeRu,
          selectedDates: dateSend,
          minDate: nowDate,
          autoClose: true,
          isMobile: width > 1000 ? false : true,
          position: "bottom center",
          onSelect: function (e) {
            setDateSend(e.date);
            setDateFormatted(e.formattedDate);
          },
        });
      }, 400);
    } else return;

    return () => {
      airDate.current.destroy();
    };
  }, [count]);

  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(tz);
  }, []);

  return (
    <div className={styles.indexformmobile}>
      {formComplete ? (
        <Complete name={nameUser} />
      ) : (
        <div className={styles.form}>
          <form id="submitindexform" onSubmit={handleSubmit(onSubmit)}>
            <h2 className={styles.title}>
              Рассчитать стоимость
              <br />
              перевозки автомобиля
            </h2>
            <p className={styles.hint}>{hints[count - 1]}</p>
            {/* Типы авто */}
            <AnimatePresence initial={false} exitBeforeEnter>
              {count === 1 && (
                <motion.div
                  key="form_typeauto"
                  variants={variantInput}
                  initial={nextAnimation ? "initialnext" : "initialback"}
                  exit="exit"
                  animate="animate"
                  className={styles.typeauto}
                >
                  <div className={styles.checkContainer}>
                    <label
                      htmlFor="Sedan"
                      className={
                        typeAuto === "Седан"
                          ? cx(styles.check, styles.active)
                          : styles.check
                      }
                    >
                      <StaticImage
                        src="../../images/typeauto/1.png"
                        alt="седан"
                        placeholder="none"
                      />
                      <p className={styles.checkLabel}>Седан</p>
                      <input
                        className={styles.checkBox}
                        type="checkbox"
                        {...register("typeauto")}
                        id="Sedan"
                        value="Седан"
                        onClick={(e) => selectTypeauto(e.target.value)}
                      />
                    </label>
                  </div>

                  <div className={styles.checkContainer}>
                    <label
                      htmlFor="Hatchback"
                      className={
                        typeAuto === "Хэтчбэк"
                          ? cx(styles.check, styles.active)
                          : styles.check
                      }
                    >
                      <StaticImage
                        src="../../images/typeauto/2.png"
                        alt="хэтчбэк"
                        placeholder="none"
                      />
                      <p className={styles.checkLabel}>Хэтчбэк</p>
                      <input
                        className={styles.checkBox}
                        type="checkbox"
                        {...register("typeauto")}
                        id="Hatchback"
                        value="Хэтчбэк"
                        onClick={(e) => selectTypeauto(e.target.value)}
                      />
                    </label>
                  </div>

                  <div className={styles.checkContainer}>
                    <label
                      htmlFor="Jeep"
                      className={
                        typeAuto === "Внедорожник"
                          ? cx(styles.check, styles.active)
                          : styles.check
                      }
                    >
                      <StaticImage
                        src="../../images/typeauto/3.png"
                        alt="Внедорожник"
                        placeholder="none"
                      />
                      <p className={styles.checkLabel}>Внедорожник</p>
                      <input
                        className={styles.checkBox}
                        type="checkbox"
                        {...register("typeauto")}
                        id="Jeep"
                        value="Внедорожник"
                        onClick={(e) => selectTypeauto(e.target.value)}
                      />
                    </label>
                  </div>

                  <div className={styles.checkContainer}>
                    <label
                      htmlFor="Universal"
                      className={
                        typeAuto === "Универсал"
                          ? cx(styles.check, styles.active)
                          : styles.check
                      }
                    >
                      <StaticImage
                        src="../../images/typeauto/4.png"
                        alt="универсал"
                        placeholder="none"
                      />
                      <p className={styles.checkLabel}>Универсал</p>
                      <input
                        className={styles.checkBox}
                        type="checkbox"
                        {...register("typeauto")}
                        id="Universal"
                        value="Универсал"
                        onClick={(e) => selectTypeauto(e.target.value)}
                      />
                    </label>
                  </div>

                  <div className={styles.checkContainer}>
                    <label
                      htmlFor="Crossover"
                      className={
                        typeAuto === "Кроссовер"
                          ? cx(styles.check, styles.active)
                          : styles.check
                      }
                    >
                      <StaticImage
                        src="../../images/typeauto/5.png"
                        alt="кроссовер"
                        placeholder="none"
                      />
                      <p className={styles.checkLabel}>Кроссовер</p>
                      <input
                        className={styles.checkBox}
                        type="checkbox"
                        {...register("typeauto")}
                        id="Crossover"
                        value="Кроссовер"
                        onClick={(e) => selectTypeauto(e.target.value)}
                      />
                    </label>
                  </div>
                </motion.div>
              )}

              {/* Марка и модель авто */}
              {count === 2 && (
                <motion.div
                  key="form_modelauto"
                  variants={variantInput}
                  initial={nextAnimation ? "initialnext" : "initialback"}
                  exit="exit"
                  animate="animate"
                  className={styles.carbrand}
                >
                  <div className={styles.input}>
                    <input
                      {...register("brandauto")}
                      name="brandauto"
                      type="text"
                      placeholder="Марка авто"
                      onChange={(e) => setMarkAuto(e.target.value)}
                      className={markAuto && styles.complete}
                    />
                  </div>
                  <div className={styles.input}>
                    <input
                      {...register("modelauto")}
                      name="modelauto"
                      type="text"
                      placeholder="Модель авто"
                      onChange={(e) => setModelAuto(e.target.value)}
                      className={modelAuto && styles.complete}
                    />
                  </div>
                </motion.div>
              )}

              {/* Откуда и куда доставлять авто */}
              {count === 3 && (
                <motion.div
                  key="form_route"
                  variants={variantInput}
                  initial={nextAnimation ? "initialnext" : "initialback"}
                  exit="exit"
                  animate="animate"
                  className={styles.location}
                >
                  <div className={styles.input}>
                    <input
                      {...register("starting_point")}
                      name="starting_point"
                      type="text"
                      placeholder="Откуда"
                      onChange={(e) => setStartingPoint(e.target.value)}
                      className={startingPoint && styles.complete}
                    />
                  </div>
                  <div className={styles.input}>
                    <input
                      {...register("final_point")}
                      name="final_point"
                      type="text"
                      placeholder="Куда"
                      onChange={(e) => setFinalPoint(e.target.value)}
                      className={finalPoint && styles.complete}
                    />
                  </div>
                </motion.div>
              )}

              {/* Дата отправления */}
              {count === 4 && (
                <motion.div
                  key="form_datepicker"
                  variants={variantInput}
                  initial={nextAnimation ? "initialnext" : "initialback"}
                  exit="exit"
                  animate="animate"
                  className={styles.datadeparture}
                >
                  <div className={styles.input}>
                    <input
                      {...register("departure_date")}
                      placeholder={dateSend ? "" : "Дата отправления"}
                      name="departure_date"
                      type="text"
                      autoComplete="off"
                      id="datePicker"
                      ref={datePicker}
                      className={
                        dateSend
                          ? cx(styles.datePicker, styles.complete)
                          : styles.datePicker
                      }
                    />
                  </div>
                </motion.div>
              )}

              {/* Удобный вариант связи */}
              {count === 5 && (
                <motion.div
                  key="form_contact"
                  variants={variantInput}
                  initial={nextAnimation ? "initialnext" : "initialback"}
                  exit="exit"
                  animate="animate"
                  className={styles.contact}
                >
                  <div className={styles.input}>
                    <input
                      {...register("name")}
                      name="name"
                      type="text"
                      placeholder="Имя"
                      className={nameUser && styles.complete}
                      onChange={(e) => setNameUser(e.target.value)}
                    />
                  </div>
                  <div className={styles.input}>
                    <input
                      {...register("phone")}
                      name="phone"
                      type="tel"
                      placeholder="Контакты для связи"
                      className={contactUser && styles.complete}
                      onChange={(e) => setContactUser(e.target.value)}
                    />
                  </div>
                  <div className={styles.input}>
                    <input
                      {...register("time")}
                      name="time"
                      type="text"
                      placeholder="Удобное время для связи"
                      className={time && styles.complete}
                      onChange={(e) => setTime(e.target.value)}
                    />
                    <input
                      {...register("promocode")}
                      name="promocode"
                      type="text"
                      placeholder="Промокод"
                      className={promoCode && styles.complete}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                  </div>
                  <p className={styles.policy}>
                    Оставляя заявку Вы соглашаетесь с<br />
                    <Link to="/privacypolicy">
                      политикой конфиденциальности
                    </Link>
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </form>
          {/* Навигации по форме */}
          <div className={styles.navigation}>
            <button
              className={
                backBtn ? styles.backstep : cx(styles.backstep, styles.disable)
              }
              onClick={() => {
                setCount(count - 1);
                setNextAnimation(false);
              }}
            >
              Назад
            </button>
            <div className={styles.count}>
              <svg
                className={styles.circle}
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <motion.circle
                  cx="50"
                  cy="50"
                  r="48"
                  animate={{ pathLength: count / 5 }}
                  transition={{ duration: 0.8, ease: [0.1, 0.4, 0.5, 1] }}
                />
              </svg>
              <p>{count} / 5</p>
            </div>
            {count < 5 ? (
              <button
                className={
                  nextBtn
                    ? styles.nextstep
                    : cx(styles.nextstep, styles.disable)
                }
                onClick={() => {
                  setCount(count + 1);
                  setNextAnimation(true);
                }}
              >
                Продолжить
              </button>
            ) : (
              <input
                className={
                  nextBtn
                    ? styles.sendform
                    : cx(styles.sendform, styles.disable)
                }
                type="submit"
                form="submitindexform"
                label="Отправить"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MobileIndexForm;
