// extracted by mini-css-extract-plugin
export var about = "index-module--about--2Mw-U";
export var advantages = "index-module--advantages--mvO0F";
export var background = "index-module--background--ZKA4f";
export var block = "index-module--block--kNsdX";
export var btn = "index-module--btn--uF0mJ";
export var callback = "index-module--callback--y339X";
export var choise = "index-module--choise--LcgZn";
export var container = "index-module--container--BhULO";
export var cooperation = "index-module--cooperation--SCn7z";
export var documents = "index-module--documents--F-8pm";
export var download = "index-module--download--igoUa";
export var form = "index-module--form--TmPHg";
export var guarantees = "index-module--guarantees--gy0Rr";
export var horizontal = "index-module--horizontal--xQo8r";
export var icon = "index-module--icon--cD24n";
export var info = "index-module--info--ukO5C";
export var item = "index-module--item--NOQQa";
export var label = "index-module--label--1G1wu";
export var layer = "index-module--layer--njJ+p";
export var left = "index-module--left--F5rx-";
export var line = "index-module--line--q6Gds";
export var link = "index-module--link--6529W";
export var links = "index-module--links--x2eDk";
export var list = "index-module--list--q2GQ7";
export var logomobile = "index-module--logomobile--gN32p";
export var main = "index-module--main--8OG11";
export var map = "index-module--map--js6sg";
export var number = "index-module--number--4BZMz";
export var phone = "index-module--phone--DlEAz";
export var right = "index-module--right--sHeil";
export var schemework = "index-module--schemework--nfRWR";
export var title = "index-module--title--oUmHu";
export var vertical = "index-module--vertical--hf46s";